div.Container {
    /* border: 1px solid red; */
    /* max-height: 100%; */
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    margin-bottom: 5rem;
    height: 70vh;  


    div.PriceInfo{
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      border-radius: 10px;
      padding: 15px;
      text-align: center;
      width: 50%;
      background-color: var(--mainColor);

      div.PriceTitle{
        font-size: 28px;
        font-weight: 500;
      }

      div.PriceDescription{
        font-size: 15px;
      }
    }
}
  
  /* @media screen and (max-width: 600px) {
      div.PPolicy {
        width: 90vw;
        display: flex;
        flex-flow: column-reverse wrap;
      }
  } */
  
  @media (max-width: 850px){
    div.Container {
      margin-bottom: 0rem;
      div.PriceInfo{
        width: 70%;
        div.PriceTitle{
          font-size: 20px;
          font-weight: 500;
        }
  
        div.PriceDescription{
          font-size: 10px;
        }
      }
    }
  }