div.ModalContainer{
    /* background: var(--bg-modal-image);
    background-size: '100%';
    background-position: 'top';
    background-color: '#FFFF';
    maxHeight: '100%'; */
    /* height: 273px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 18px; */

    h3{
        text-align: center;
        width: 80%;
        font-size: 23px;
    }

    h4{
        width: 80%;
    }

    button{
        margin-top: 10px;
        font-family: Heebo;
        outline: none;
        width: 185px;
        height: 35px;
        border-radius: 25px;
        font-size: 18px;
        align-text: center;
        font-weight: 600;
        background-color: unset;
        cursor: pointer;
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
    }

    h3{
        text-overflow: ellipsis;
    }
}