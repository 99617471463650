div.Container{
    justify-content: center;
    position: relative;
    overflow: auto;

    div.CloseButton {
        margin-bottom: 50px;
        button{
            position: absolute;
            width: 40px;
            font-size: 25px;
            font-weight: 900;
            left: 6px;
            top: 7px;
            background-color: unset;
            color: #000;
            cursor: pointer;
            border: none;
            outline: none;
        }
    }

    div.CartItemName{
        margin-top: 20px;
        font-size: 20px;
        font-weight: 700;
        color: var(--mainColor);
    }

    div.PackItemToOrder{
        margin-left: 7%;
        margin-right: 7%;
        margin-top: 2%;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #D3D3D3;

        div.RightElements{
            display: flex;
            margin-bottom: 5px;

            /* ItemImage{
                img{
                    width: 100px;
                } 
            } */

            div.ItemDetails{
                margin: auto;
                display: flex;
                flex-direction: column;
                gap: 3vh;
                margin-right: 25px;
                
                div.ItemName{
                    font-size: 15px;
                    font-weight: 500;
                    color: var(--mainColor);
                }

                div.ItemQuantity{
                    font-size: 13px;
                    font-weight: 700;
                    text-align: right;
                }
            }
        }

        div.LeftElements{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: relative;
            gap: 1vh;

            div.MoreInfoTooltip{
                position: absolute;
                flex: 1;
                top: 0px;
                left: 0;
                font-size: 10px;
                text-decoration: underline;
                /* color: #B30C00; */
                cursor: help;
                color: var(--mainColor);

                .Tooltip{
                    max-width: 400px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }

                *:hover{
                    opacity: .6;
                }
            }

            button{
                border-radius: 10px;
                width: 100px;
                background-color: #FFFFFF;
                outline: none;
                cursor: pointer;
                color: var(--mainColor);
                border: 1px solid var(--mainColor);
            }
        }
    }
}

@media (max-width: 440px){
    div.Container{
        div.PackItemToOrder{
            div.RightElements{
                /* ItemImage{
                    img{
                        width: 70px;
                    } 
                } */

                div.ItemDetails{
                    div.ItemName{
                        font-size: 13px;
                    }
                }
            }
            
            div.LeftElements{
                button{
                    width: 80px;
                    font-size: 11px;
                }
            }
        }
    }
}