.TopApp {
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
  /* height: 100%; */
  height: 100vh;
  max-width: 100%;
  background-color: rgb(222, 222, 224);
  /* height: 100%; */
  background-size: 100%;
  background-position: center;
  position: relative;
  overflow: hidden;
  
  /* font-family: sans-serif;
  background-size: 100%;
  background-position: center;
  background-color: #0000;
  min-height: 100%;
  min-width: 100%; 
*/

  div.Whatsapp{
    position: fixed;
    left: 15px;
    bottom: 80px;
    z-index: 1;

    img{
        opacity: .9;
    }
    

  }

  .App {
    margin: 0;
    padding: 0;
    display: flex;
    height: 100vh;
    
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    .AppHeader {
      display: flex;
      flex-direction: row-reverse;
      align-content: center;
      background:transparent;
      padding: 5px;
      padding-left: 15px;
      flex: 0 0 100px;

      .GoToStoresBtn{
        /* border: 1px solid red; */
        max-height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
  
        div.StoresImg{
          padding-top: 10px;
          margin-right: 10px;
          margin-left: 10px;

          img{
            width: 50px;
            height: 50px;
          }
          img:hover{
            cursor: pointer;
          }
        }
      }

      .AppHeader__Login {
        /* border: 1px solid red; */
        justify-self: end;
        max-height: 130px;
        display: flex;
        align-items: center;

        .SocialIcons{
          margin-left: 15px;
          display: flex;
          justify-content: space-around;
        }

        .SocialIcons > *{
          cursor: pointer;
        }
        .SocialIcons > * + *{
          margin-right: 5px;
        }
        
        .DivLogin {
          display: flex;
          flex: 1;
          /* align-self: center; */
          flex-flow: row-reverse wrap;
          text-align: center;
          justify-content: center;

          .LoginIcon {
            width: 50px;
            height: 50px;
            text-align: center;
            margin: 10px 0 0 0;
          }
        }

        .LoginText {
          align-content: center;
          margin: 14px 14px 0 10px;
          color: var(--mainWhite);
        }
      }

      div.CartInfo{
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top : 20px;
        border: 1px solid #FFFFFF;
        border-radius: 50px;
        width: 90%;
        height: 50%;
        color: #FFFFFF;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 2px;
        padding-bottom: 2px;
        
        div.CartInfoTitle{
          /* border: 1px solid red; */
          color: rgb(255, 255, 255, 0.9);
          width: min-content;
          font-size: 10px;
          font-weight: 0;
        }
        
        div.CartSum{
          font-size: 20px;
          font-weight: 400;
        }
        
        div.CartWrapper{
          position: relative;
          width: 40px;
          display: flex;
          justify-content: flex-end;

          span.ItemsInCart{
            /* border: 1px solid red; */
            position: absolute;

            left: 40%;
            top: 18%;
            width: 15px;
            height: 15px;
            
            text-align: center;
            border-radius: 50%;
            background-color: var(--mainColor);
            
            span{
              display: flex;
              justify-content: center;
              margin-top: 1px;
              font-size: 10px;
            }
          }

          img{
            width: 25px;
            height: 25px;
          }
        }

      }
    }

    .AppBody {
      /* flex: 1 1; */
      /* max-height: calc(100% - 200px); */
      /* height: 100%; */
      z-index: 1;
    }

    .AppFooter {
      /* border: 3px solid red; */
      position: fixed;
      bottom: 0;
      /* left: 0; */
      /* right: 0; */
      height: 6.3%;
      width: 100%;
      overflow: hidden;
      background: transparent;
      z-index: 10;
      /* display: flex;
      justify-content: center;
      align-items: center; */
/* 
      .AppFooterA {
        height: 55px;
      } */
    }
  }
}

.LoginHere{
  align-self:flex-end;
}
.AppLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  max-width: 80px !important;
  /* max-height: 100px !important; */
  height: 80px;
  margin: 5px;
  justify-self:center;
  margin-right: 10px;
  margin-left: 10px;
  overflow: hidden;
  /* pointer-events: none; */

  img {
    height: 80px;
    position: relative;
    max-height: initial;
    max-width: initial;
  }
}


.AppTopMenu {
  text-align: center;
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: flex-end;
  align-content: center;
  padding: 5px;
  flex: 1 1;
}
.AppTopMenu.button {
  background-color: transparent;
  color: var(--mainWhite);
}



.bg_second {
  background-color: var(--thinScrBg);
}

.BigappsLogo{
  max-width: 100px;
  height: 30px;
}

/* @media (max-width: 850px){
  div.TopApp{
    div.App{
        div.AppHeader{
          div.AppHeader__Login {
            div.DivLogin{
              div.LogIconDiv{
              }
            }
          }
        }
    }
  }
} */


/* @media screen and (max-width: 600px) {
    .AppTopMenu {
      display: flex;
      flex-flow: column-reverse wrap;
    }
    .AppHeader {
      color:black;
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .AppLogo {
      display: none;
    }
    .AppBody{
      margin-bottom: 50px;
      display: flex;
      flex-flow: column-reverse;
      justify-content: space-around;
    }
  } */


  @media (max-height: 300px) {
    div.TopApp{
      div.Whatsapp{
        width: 0;
      }
    }
  }