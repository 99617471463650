/* @media screen and (min-width: 601px) { */

div.Container{
    /* border: 4px solid brown; */
    /* position: relative; */
    height: 100%;
    overflow: auto;
    /* width: 100%; */
    /* overflow: clip; */

    div.ToppingsDescription{
        font-size: 14px;
        margin-bottom: 5px;
    }

    div.ItemToppingList{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* gap: 10px; */
        padding: 0 15%;
        

        div.ItemTopping{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            font-weight: 500;
            font-size: 20px;

            div.WrapBtnAndName{
                flex-basis: 31%;
                flex-grow: 3;
                min-width: 1%;
                white-space: pre-wrap;
                /* text-align: right; */
                .ItemToppingName{
                    min-width: 70%;
                    overflow-wrap: break-all;
                }                
            }
            .ItemToppingPrice{
                flex: 5 1 1;
            }

        }
    }

    div.ItemToppingList > * + *{
        margin-top: 10px;
    }
    div.btnContainer{
        flex: 1 1;
        max-width: 80px;
        min-width: 70px;

        div.InputWithCounter{
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            align-content: flex-end;
            justify-content: center;
            border-radius: 25px;
            border-width: 0px;
            margin-right: 5px;
            height: 30px;
            position: relative;
            background-color: var(--mainColor);

            div.buttonPlus{
                position: absolute;
                margin-top: 3px;
                margin-right: 5px;
                right: 3px;
                color: white;
                line-height: 25px;
                font-size: 33px;
                font-weight: 300;
                /* color: var(--mainWhite); */
            }
            div.ItemAmount{
                /* width: 25px; */
                margin-top: 3px;
                align: center;
                color: white;
                line-height: 25px;
                font-size: 20px;
                font-weight: 400;
                /* color: var(--mainWhite); */
            }
            

            div.buttonMinus{
                position: absolute;
                margin-top: 3px;
                margin-left: 5px;
                left: 3px;
                color: white;
                line-height: 25px;
                font-size: 53px;
                font-weight: 300;
                /* color: var(--mainWhite); */
            }
            div.buttonMinus:hover{cursor: pointer;}
            div.buttonPlus:hover{cursor: pointer;}
            
        }
    }

    div.RightSideTabs{
        display: flex;
        flex-direction: column;
        direction: ltr;
        position: absolute;
        right: 0;
        top: 50px;
        max-height: 100%;
        overflow: auto;
        font-size: 12px;
        width: 80px;
        z-index: 20;
        
        div.SelectedToppings {
            display: flex;
            flex-direction: column;
            div.ToppingToEdit{
                color: #FFF;
                display: flex;
                border-radius: 20px 0 0 20px;
                justify-content: space-between;
                align-items: center;
                cursor: default;
                opacity: .7;
                background-color: var(--mainColor);

                div.ToppingNameAndPrice{
                    text-align: right;
                    div.ToppingName{
                        width: 60px;
                        overflow: hidden !important;
                        text-overflow: ellipsis !important;
                        span.ToppingXRemover{
                            padding-left: 3px;
                            cursor: pointer;
                        }
                        span.ToppingXRemover:hover{}
                    }
                    div.ToppingPrice{
                        padding-right: 13px;
                    }  
                }
            }
            div.ToppingToEdit:hover{}
        }

        div.SelectedToppings > * + * {
            margin-top: 5px;
        }
    }

}

@media (max-resolution: 110dpi){
    div.Container{
        div.ToppingsDescription{
            font-size: 18px;
        }

        div.ItemToppingList{
            div.ItemTopping{
                font-weight: 500;
                font-size: 20px;
            }
        }
    }
}

@media (min-resolution: 130dpi){
    div.Container{
        div.ToppingsDescription{
            font-size: 12px;
        }

        div.ItemToppingList{
            div.ItemTopping{
                font-weight: 500;
                font-size: 12px;

                input{
                    width: 10px;
                }
            }
        }
    }
}

@media (min-resolution: 150dpi){
    div.Container{
        div.ToppingsDescription{
            font-size: 10px;
        }

        div.ItemToppingList{
            div.ItemTopping{
                flex-wrap: nowrap;
                font-weight: 500;
                font-size: 10px;
            }
        }
    }
}
/* } */

@media (max-width: 850px){
    div.Container{
        div.ToppingsDescription{
            font-size: 18px;
        }
        
        div.ItemToppingList{
            padding: 0 3%;
            div.ItemTopping{
                div.WrapBtnAndName{
                    input{
                        width: 20px;
                    }
                    .ItemToppingName{
                        font-size: 17px;
                    }
                }
                .ItemToppingPrice{
                    font-size: 17px;
                    white-space: wrap;
                }
            }
        }
        div.btnContainer{
            max-width: 120px;
            min-width: 80px;
            div.InputWithCounter{
                margin-right: 5px;
                bottom: 5px;
                height: 40px;
                div.buttonPlus{
                    right: 4px;
                    line-height: 35px;
                    /* font-size: 33px; */
                }
                div.ItemAmount{
                    /* width: 25px; */
                    line-height: 35px;
                    /* font-size: 20px; */
                }
                div.buttonMinus{
                    left: 4px;
                    line-height: 35px;
                    /* font-size: 53px; */
                }
            }
        }
    }
}